// =========================================================================
    // Back Button Directive
    // =========================================================================

    // use history.back to navigate backwards
    
(function(){
    "use strict";

		angular.module('app.directives').directive('back', function() {
	    return {
	        restrict: 'A',
	        link: function( scope, element, attrs ) {
	            element.on( 'click', function () {
	                history.back();
	                scope.$apply();
	            } );
	        }
	    };
		} );
})();