(function() {
	"use strict";

	angular.module('app.controllers').controller('TermsController', TermsController);

	function TermsController($log, $state, ToastService, ShiftCtrlUserFactory, StepFactory, user) {	
		var vm = this;
		vm.user = user;
		StepFactory.setPercent(100);
	}

})();
