(function() {
	"use strict";

	angular.module('finlayer').factory('TransActAccountFactory', function(API) {
		var factory = {};
		var prefix = 'transact/account';
		
		factory.postLogin = function(data, site_id){			
			var url;
			
			if(site_id != null){
				url = prefix + '/login/' + site_id;
			}else{
				url = prefix + '/login'
			}
			
			var request = API.all(url).post(data)
				.then(function(response){
					return response.Site;
				});
				
				return request;
		}
		
		factory.getProgress = function(site_id){       
	    var request = API.one(prefix + '/progress/' + site_id).withHttpConfig({ignoreLoadingBar: true}).get()
	    	.then(function(response){
	      	return response.State;
	    	});        
	    	
	    	return request;
    }
    
    factory.getProgressAll = function(){       
	    	
        var request = API.one(prefix + '/progress-enabled').get()
        	.then(function(response){
	        	return response.States;
        	});
        	
        	return request;
    }
    
    factory.getAll = function(){       
      var request = API.one(prefix + '/index2').get()
      	.then(function(response){
        	return response.Accounts;
      	});
        	
     	return request;
    }
    
    factory.postEnable = function(data){
			var request = API.all(prefix + '/enable').post(data)
				.then(function(response){
					return response.Accounts;
				});
				
				return request;
    }
    
    factory.getMfaChallenge = function(site_id){       
	    var request = API.one(prefix + '/challenge/' + site_id).get()
	    	.then(function(response){
	      	return response;
	    	});        
	    	
	    	return request;
    }
    
    factory.postMfaChallenge = function(data){
			var request = API.all(prefix + '/challenge').post(data)
				.then(function(response){
					return response.Status;
				});
				
				return request;
    }

		return factory;
	});
})();
