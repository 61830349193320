(function() {
	"use strict";

	angular.module('app.controllers').controller('ConfirmController', ConfirmController);

	function ConfirmController($log, $state, $stateParams, $localStorage, $window, cfpLoadingBar) {	
		var vm = this;
		//cfpLoadingBar.start();
		$localStorage.lead_params = $stateParams;
		
		var url = '';
		var params = 'request='+$localStorage.lead_params.amount+'&first_name='+$localStorage.lead_params.first_name+'&surname='+$localStorage.lead_params.surname+'&email='+$localStorage.lead_params.email;
		
		if($localStorage.lead_params.hasOwnProperty('jot_form_id')){
			params = params+'&jot_form_id='+$localStorage.lead_params.jot_form_id;
		}
				
		if(typeof($stateParams.email) != 'undefined'){
			url = 'https://www.businessfuel.co.za'
			if($stateParams.location == 'Affiliate'){
				url += '/referralconfirmation.php?'+params;
			}else{
				url += '/confirmation.php?'+params;				
			}			
		}else{
			url = '/';
		}
		
		$window.location.href = url;					
	}

})();
