(function(){
		"use strict";

	angular.module('app.filters').filter('shortNumber', function() {
		return function(number) {
						var suffix = '';
						
						if(!isNaN(number)){
							if(number > 0){
								var abs = Math.abs(number);
				        if (abs >= Math.pow(10, 12)) {
				            // trillion
				            number = (number / Math.pow(10, 12)).toFixed(1);
				            suffix = 'T';
				        } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
				            // billion
				            number = (number / Math.pow(10, 9)).toFixed(1);
				            suffix = 'B';
				        } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
				            // million
				            number = (number / Math.pow(10, 6)).toFixed(1);
				            suffix = 'M';
				        } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
				            // thousand
				            number = (number / Math.pow(10, 3)).toFixed(1);
				            suffix = 'K';
				        }
			        }else{
				        number = 0;
			        }
						}else{
							number = 0;
						}

		        return number.toString()+suffix;
		   
		};
	});
})();