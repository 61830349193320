(function() {
	"use strict";

	angular.module('finlayer').factory('TransActSiteFactory', function(API) {
		var factory = {};
		var prefix = 'transact/site';
		
		factory.get = function(site_id){
			var request = API.one(prefix + '/view/' + site_id).get()
				.then(function(response){
					return response.Site;
				}); 			
				
				return request;
		}
	
		return factory;
	});
})();
