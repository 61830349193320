(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActLoginController', TransActLoginController);
	
	function TransActLoginController(_, $state, $stateParams, $log, API, TransActAccountFactory, EventFactory, $timeout, cfpLoadingBar, banks, site){
		var site_id = null;
		var vm = this;
		vm.selectedBank = null;
		vm.data = {LoginForm:null};	
		
		/*var bankImages = {
			15376: {
				'original': '/img/banks/FnbMC.png',
				'hover': '/img/banks/fnb.png'
			}			
		};*/
		
		var my_banks = new Array();
		angular.forEach(banks, function(bank, key) {		
			var not_supported = [1234, 16188, 15536, 21000, 15393, 15413, 21001];																
			if(! not_supported.includes(bank.site_id)){
				my_banks.push(bank);
			}
		});		
		
		/*angular.forEach(my_banks, function(bank, key){
			angular.forEach(bankImages, function(images, site_id){
				console.log(bank);
				console.log(site_id);
				if(bank.site_id == site_id){
					bank.logo = images;
				}
			})
		});*/
		
		function initializeFields(){
			var placeHolders = {
				15393: {
					'field1': {title: 'Access account number'},
					'field2': {title: 'PIN'},
					'field3': {title: 'User Number', value: '1'},
					'field4': {title: 'Password'}												
				},
				
				15376: {
					'field1': {title: 'Username'}										
				}
			};
			
			angular.forEach(banks, function(bank, key) {		

				/*angular.forEach(not_supported, function(ns){
					if(ns == bank.site_id){
						banks[key].not_supported = true;
					}
				});*/
				angular.forEach(placeHolders, function(newFields, site_id){
					if(bank.site_id == site_id){
						angular.forEach(bank.login_fields, function(field){
							angular.forEach(newFields, function(newFieldProperties, newField){
								if(field.name == newField){
									angular.forEach(newFieldProperties, function(fieldValue, fieldProperty){
										field[fieldProperty] = fieldValue;
										
										if(fieldProperty == 'value'){
											vm.data.LoginForm = {LOGIN1: 1};
										}
									})
								}
							});
						});
					}
				})
	 		}); 
 		}
		
		vm.banks = my_banks;
			
		vm.getBankFields = getBankFields;
		vm.submit = submit;

		if(site != null){
			vm.site = site;
			getBankFields(site.bank_id);
		}else{
			var bank = _.findWhere(vm.banks, {title : 'FNB'});
			
			getBankFields(bank.bank_id);
		}
		if($stateParams.error_code != null && $stateParams.error_msg){
	    vm.error = {code: $stateParams.error_code, msg: $stateParams.error_msg};
    }
    
    vm.resetBankFields = function(){
			$state.go('private.app.trans_act.login', {site_id: undefined, error_code: undefined, error_msg: undefined}, {reload: true});
    }
		
		function getBankFields(bank_id){
			cfpLoadingBar.start();
			
			$timeout(function(){
				vm.data = {};
				initializeFields();
				var bank = _.findWhere(vm.banks, {bank_id : bank_id});
				angular.forEach(vm.banks, function(bank, key) {
					bank.active = false;
				});
						
				vm.selectedBank = bank;
				
				vm.bank = bank;				
				vm.bank.active = true;
				$timeout(function(){
					cfpLoadingBar.complete();					
				}, 500)
			}, 500);
		}
		
		function submit(){
			TransActAccountFactory.postLogin(vm.data, site_id)
				.then(function(site){
					EventFactory.triggerRefreshStarted({bank: vm.selectedBank.title})
					.then(function(){
						$state.go('private.app.trans_act.progress', { site_id: site._id})            						
					});					
				}, function(err){
					$state.go($state.current, {}, {reload: true});
				});
		}
	}
})();