(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActConfirmIncomeController', TransActConfirmIncomeController);
	
	function TransActConfirmIncomeController(_, API, $scope, $state, $log, $timeout, TransActTransactionFactory, StepFactory, transactions){
		var vm = this;
		vm.transactions = {};
		vm.data = {};
		vm.total = 0;
		
		var ts = _.clone(transactions);
		
		for (var i = ts.length - 1; i >= 0; i--) {
		    if(ts[i].amount < 500){
				ts.splice(i, 1);
			}
		}

		vm.transactions = ts;

		
    vm.checkTransaction = function(t){	    	
    	vm.total = calcTotal();
    }
    
    function calcTotal(){
    	var total = 0;
    	_.each(vm.data.Transactions, function(state, tid){
    		
    		if(state === true){	    			
    			total += Math.abs(getTransactionAmount(tid));
    		}
	    });
    	
    	return total;
    }
    
    function getTransactionAmount(tid){
    	var amount = 0;
    	_.each(transactions, function(t, key){
    		if(tid == t._id){
    			amount = t.amount;
    		}
    	});
    	
    	return amount;
    }
	
		vm.submit = function(){
			var proceed = false;
			_.each(vm.data, function(status, account){
	    		if(status){
	    			proceed = true;
	    		}
	    	});
	    	
	    	if(proceed == false){
		    	return;
	    	}
	    	
	    	TransActTransactionFactory.postIncome(vm.data.Transactions)
	    		.then(function(income){		    		
						$state.go('private.app.trans_act.confirm_expenses');
	    		}, function(error){
//	    			$state.go('private.app.trans_act.login');
	    		});
		}
		StepFactory.setPercent(80);
	}
})();