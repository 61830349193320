//==============================================
// Loan Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('LoanFactory', function($q) {
		var factory = {};
		var documents = [
			{
				'name': 'sars_vat',
				'title': 'SARS VAT Statements',
			},
			{
				'name': 'sars_paye',				
				'title': 'SARS PAYE'
			},
			{
				'name': 'management_accounts',
				'title': 'Management Accounts'
			}
		];
		
		var document_ranges = {
			'500000-1000000': ['sars_vat', 'sars_paye', 'management_accounts']
		};
		
		factory.getRequiredDocs = function(loan_amount){
			var required = [];
			var deferred = $q.defer();		
			
			var required = [];
			angular.foreach(document_ranges, function(required_docs, key){
				angular.foreach(required_docs, function(doc_name, doc_key){
					angular.foreach(documents, function(doc){
						if(doc.name == doc_name){
							required.push(doc);							
						}
					})
				})
			});
				
			deferred.resolve(required);
			return deferred.promise;
		}
		
		return factory;
	});
})();