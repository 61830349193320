(function() {
	"use strict";

	angular.module('finlayer').factory('TransActTransactionFactory', function(API) {
		var factory = {};
		var prefix = 'transact/transactions';
		
		factory.getIncome = function(){
			var request = API.one(prefix + '/income').get()
				.then(function(response){
					return response.Transactions;
				});
				
				return request;
		}
		
		factory.getExpenses = function(){
			var request = API.one(prefix + '/expenses').get()
				.then(function(response){
					return response.Transactions;
				});
				
				return request;
		}
		
		factory.postIncome = function(data){
			var request = API.all(prefix + '/income').post(data)
				.then(function(response){
					return response.Transactions;
				});
				
				return request;
		}
		
		factory.postExpenses = function(data){
			var request = API.all(prefix + '/expenses').post(data)
				.then(function(response){
					return response.Transactions;
				});
				
				return request;
		}

		return factory;
	});
})();
