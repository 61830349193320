(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActStatementTypeController', TransActStatementTypeController);
	
	function TransActStatementTypeController(_, $state, banks, StepFactory){
		var vm = this;
		vm.data = {};	
		vm.submit = submit;
		vm.accordion = {};
		
		angular.element('.modal-dialog').removeClass('w-900');		
		
		vm.banks = _.filter(banks, function(bank){
			return bank.statement == true;
		});
		
			vm.accordion.bs = [
			{
				'title': 'How do I get my PDF bank statements?',
				'content': 'We provide detailed guidance on how to obtain these from your specific bank. Clicking “Next” will take you through to this information.'
			},
			{
				'title': 'Why can\'t I send scanned bank statements?', 
				'content': 'Often scanned statements are illegible and difficult to read. To ensure we can provide an offer in principle as quickly as possible, we need to receive your online banking statements. We provide guidance on exactly how to do that above.'
			},			
			{
				'title': 'Are my bank statements safe?', 
				'content': 'Your bank statements are submitted to us through a secure portal. We take all reasonable measures to protect the information that you provide to us. These measures include, but are not limited to preventing unauthorised access or disclosure and ensuring the appropriate use of information.'
			},								
		];					
		
			angular.forEach(vm.banks, function(bank, key) {
				if(bank.site_id == 16188 || bank.site_id == 15536){
					vm.banks.splice(key, 1)
				}
	 		});		
		
		function submit(){
			$state.go('private.app.trans_act.statement_upload', {bank_id: vm.data.bank.bank_id, bank: vm.data.bank.class});
		}
		StepFactory.setPercent(50);
	}
})();