(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActDisclaimerController', TransActDisclaimerController);

	function TransActDisclaimerController($log, StepFactory, $window) {	
		var vm = this;
		
		vm.goBack = function(){
			$window.location.href = 'https://www.genfin.co.za?utm_source=connect_decline'
		};
		StepFactory.setPercent(50);
	}

})();
