//==============================================
// Connect Request Factory
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('ConnectRequestFactory', function ($http, $q, $log, $localStorage, __env, API) {
    var factory = {};
    var connectRequest = null;
    
    factory.initialize = function(params){	    	    
    	var deferred = $q.defer();
    	connectRequest = getConnectRequest();
    	
    	if(connectRequest == null){		    
		    if(params.redirect_uri != null && params.scope != null){
			    $log.info('Generating new Connect Request');
			    generateConnectRequest(params)
    			.then(function(data){
    				
    				if(data.ConnectRequest){
    					storeConnectRequest(data.ConnectRequest);
    					deferred.resolve(data.ConnectRequest);  
    				}    				    				    				
    			}, function(err){
    				deferred.reject(err);
    			});  
		    }else{
			    deferred.reject();
		    }
    		  		
    	}else{
    		storeConnectRequest(connectRequest);
    		deferred.resolve(connectRequest);  
    	}    	
    	
		return deferred.promise;
    }
    
    factory.getConnectRequest = getConnectRequest;
    factory.reset = reset;
    
    function storeConnectRequest(connectRequest){
    	$localStorage.ConnectRequest = connectRequest;
    	//$http.defaults.headers.common['ConnectRequestId'] = connectRequest._id;
    }
    
    function getConnectRequest(){    
    	if(connectRequest == null){
	    	if($localStorage.ConnectRequest){
			    connectRequest = $localStorage.ConnectRequest;		
	    	}
    	}    	
			$log.info('ConnectRequest: '+ connectRequest);
    	return connectRequest;    	
    }
    
    function generateConnectRequest(params){
	    var data = { client_id: __env.clientId, redirect_uri: params.redirect_uri, scope: params.scope};
    	return API.all('/connect/verify').post(data);
    }
    
    function reset(){
	    connectRequest = null;
	    delete $localStorage.ConnectRequest;
    }
    
    return factory;
});
})();