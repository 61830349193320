(function() {
	"use strict";

	angular.module('finlayer').factory('CreditCrunchReportFactory', function(API) {
		var factory = {};
		var prefix = 'credit_crunch/report';
		
		factory.get = function(){
			var request = API.one(prefix).withHttpConfig({ cache: true }).get()
				.then(function(response){
					return response.CreditReport;
				}, function(err){
					return {};
				});
				
				return request;
		}
		
		factory.getDebtRepayments = function(){
			return API.all(prefix + '/debt-repayments').getList();
		}
		return factory;
	});
})();
