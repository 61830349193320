(function() {
	"use strict";

	angular.module('finlayer').factory('SecurityOtp', function(API) {
		var factory = {};
		var prefix = 'finlayer/security/otp';
		
		factory.getSend = function(number){			
			return API.one(prefix + '/send/'+ number).get();
		}
		
		factory.getConfirm = function(code){
			return API.one(prefix + '/confirm/' + code).get();
		}
		return factory;
	});
})();
