(function(){
	angular.module('app.filters').filter('abs', function(){
		return function(input) {
			try {
				return Math.abs(input);  
			} catch(e){
				return input;
			}
		};
	});
})();