(function() {
	"use strict";

	angular.module('finlayer').factory('CreditCrunchIdentity', function(API) {
		var factory = {};
		var prefix = 'credit_crunch/identity';
		
		factory.create = function(data){
			var request = API.all(prefix + '/update').post(data)
				.then(function(response){
					return response.Status;
				});
				
				return request;
		}
		
		factory.get = function(){
			return API.one(prefix + '/view').get();
		}
		
		return factory;
	});
})();
