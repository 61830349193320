(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActOptionsController', TransActOptionsController);

	function TransActOptionsController($log, StepFactory) {	
		var vm = this;
		
		StepFactory.setPercent(50);
		vm.accordion = {};
		
		vm.accordion.yodlee = [
			{
				'title': 'What is Yodlee?',
				'content': 'The fastest, easiest and most secure option, Yodlee is a world-renowned service trusted by reputable third-party providers such as Xero and SageOne as well as major South African banks.'
			},
			{
				'title': 'How does this work?', 
				'content': 'Genfin uses Yodlee, a third-party online banking solution provider. Yodlee connects to thousands of banking websites worldwide to retrieve bank statement information and make this available for use. Yodlee accesses your online banking using the login credentials you provide and retrieves your bank statement information.'
			},			
			{
				'title': 'How safe are my details?', 
				'content': 'Yodlee stores your online banking login credentials in a highly secure system. To be able to access your online banking website, Yodlee needs to store a copy of your online banking credentials. Yodlee then logs into your banking website on your behalf and retrieves your bank statement information. This bank statement information is securely transferred to Genfin.  If your online banking website is set up to send you an email or SMS notification that you have logged on to your internet banking account, you will also receive a notification each time Yodlee accesses and retrieves your bank statement information.'
			},								
		];
		
			vm.accordion.bs = [
			{
				'title': 'How do I get my PDF bank statements?',
				'content': 'We provide detailed guidance on how to obtain these from your specific bank. Clicking “Next” will take you through to this information.'
			},
			{
				'title': 'Why can\'t I send scanned bank statements?', 
				'content': 'Often scanned statements are illegible and difficult to read. To ensure we can provide an offer in principle as quickly as possible, we need to receive your online banking statements. We provide guidance on exactly how to do that above.'
			},			
			{
				'title': 'Are my bank statements safe?', 
				'content': 'Your bank statements are submitted to us through a secure portal. We take all reasonable measures to protect the information that you provide to us. These measures include, but are not limited to preventing unauthorised access or disclosure and ensuring the appropriate use of information.'
			},								
		];		
	}

})();
