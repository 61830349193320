(function() {
	"use strict";

	angular.module('finlayer').factory('MoneySummaryHtmlFactory', function(API) {
		var factory = {};
		var prefix = 'money_summary/html/link';
		
		factory.getLink = function(){			
			return API.one(prefix + '/link').get();
		}

		return factory;
	});
})();
