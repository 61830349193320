(function() {
	"use strict";

	angular.module('finlayer').factory('BankStatementStatementFactory', function(API, $q) {
		var factory = {};
		var prefix = 'bank_statement/statement';
		
		factory.postParse = function(data){
			return API.all(prefix + '/parse').post(data);
		}
		
		factory.getBanks = function(){					
			var request = API.one(prefix + '/banks').get()
				.then(function(response){
					return response.Banks;
				});	
				return request;
		}		
		
		return factory;
	});
})();
