//==============================================
// Province Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('ProvinceFactory', function($q) {
		var factory = {};
		var provinces = [
			'Eastern Cape',
			'Free State',
			'Gauteng',
			'Kwazulu-Natal',
			'Mpumulanga',
			'Northern Cape',
			'Limpopo',
			'North West Province',
			'Western Cape'
		];
		
		factory.getAll = function(){
			var deferred = $q.defer();			
			deferred.resolve(provinces);
			return deferred.promise;
		}
		
		return factory;
	});
})();