(function() {
	"use strict";

	angular.module('app.controllers').controller('CompleteController', CompleteController);
	
	function CompleteController(API, $state, $window, StepFactory, user){
		var vm = this;
		vm.user = user;
		vm.showContinue = false;	
		
		vm.complete = function(){
			$window.location.href = 'https://www.businessfuel.co.za/';		
		}
		
		StepFactory.setPercent(100);		
	}
	
})();
