(function() {
	"use strict";

	angular.module('app.controllers').controller('CreditCrunchDeclinedController', CreditCrunchDeclinedController);
	
	function CreditCrunchDeclinedController(API, $state, $timeout, $log, CreditCrunchIdentity, StepFactory, user){
		var vm = this;
		vm.confirm = confirm;
		vm.user = user;
		
		function confirm(){			
			var data = {
				identifier : vm.identifier
			}
			
			CreditCrunchIdentity.create(data)
				.then(function(response){	
						$log.info(response);			
						$state.go('private.app.credit_crunch.declined');			
				});
		}
		
				StepFactory.setStep(1);
	}
	
})();
