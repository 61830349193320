(function() {
	"use strict";

	angular.module('finlayer').factory('TransActBankFactory', function(API) {
		var factory = {};
		var prefix = 'transact/bank';
		
		factory.getPopular = function(){		
			
			var request = API.one(prefix + '/popular').get()
				.then(function(response){
					return response.Banks;
				});	
				return request;
		}
	
		return factory;
	});
})();
