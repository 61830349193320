(function(){
	"use strict";

	angular.module('app',
		[
		'app.controllers',
		'app.filters',
		'app.services',
		'app.directives',
		'app.routes',
		'app.config',
		'partialsModule',
		'ngSanitize'
		]); 

	angular.module('app.routes', ['ui.router', 'ui.bootstrap']);
	angular.module('app.controllers', ['ui.router', 'ngStorage', 'ngAnimate', 'restangular', 'angular-loading-bar', 'ngMessages', 'localytics.directives', 'google.places', 'underscore']);
	angular.module('app.filters', []);
	angular.module('app.services', ['finlayer']);
	angular.module('app.directives', ['angularUtils.directives.dirPagination', 'ng-currency', 'ngDropzone', 'ngMask', 'nvd3', 'ngOnload']);
	angular.module('app.config', []);
	
	angular.module('app').constant('__env', __env);  
	angular.module('app').constant('_',
	    window._
	);
	
	angular.module('app').run(function($rootScope, $window, $location, __env){
		
		$rootScope.__env = __env;
		
		var stateChangeProcess = function(event, current, previous){
			$window.scrollTo(0, 0);
		}
		
      $rootScope.$on('$stateChangeSuccess', function (event, current, previous) {
          stateChangeProcess(event, current, previous);        
      }); 
         	
      $rootScope.$on('$stateChangeError', function (event, current, previous) {
          stateChangeProcess(event, current, previous);        
      });		
    
  });	

})();

