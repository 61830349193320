(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActAccountsController', TransActAccountsController);
	
	function TransActAccountsController(_, API, $state, $log, TransActAccountFactory, StepFactory, accounts){
		var vm = this;
		vm.accounts = accounts;
		
		vm.bankAccounts = _.groupBy(accounts, function(b) {					
		    return b.site.title;
		});		
	
		vm.submit = function(){			
			var state = 'private.app.complete';				
			var proceed = false;
			
			_.each(vm.data['Accounts'], function(status, account_id){
	    		if(status){
	    			proceed = true;
	    		}
					
					var acc = _.where(accounts, {_id: account_id});
					if(acc[0].source == 'BankStatement'){	
			  		state = 'private.app.trans_act.confirm_overdraft';
					}
					    		
	    	});
	    	
	    	if(proceed == false){
		    	return;
	    	}
	    	
	    	TransActAccountFactory.postEnable(vm.data['Accounts'])
	    		.then(function(accounts){
		    		
						$state.go(state);
	    		}, function(error){
	    			//$state.go('private.app.trans_act.progress');
	    		});
		}
				StepFactory.setPercent(90);
	}
})();