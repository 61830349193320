(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActConfirmExpensesController', TransActConfirmExpensesController);
	
	function TransActConfirmExpensesController(_, API, $state, $log, TransActTransactionFactory, StepFactory, transactions, debt_repayments, categories){
		var vm = this;
		vm.debt_repayment_total = 0;
		vm.debtPaymentsCollapsed = false;
		vm.transactions = transactions;
		vm.debt_repayments = debt_repayments;
		vm.categories = categories;
		vm.manualExpense = [];
		
		vm.data = {};
		vm.data.Transactions = {};
		vm.calcTotal = calcTotal;
		
		
		vm.showEmpty = function(){
			return vm.manualExpense.length;
		}
		
		vm.addManualExpense = function(){
			vm.manualExpense.push({"category":"","amount":''});
		}
		
		vm.removeManualExpense = function(index){
			vm.manualExpense.splice(index, 1);
		}
		if(!_.isEmpty(debt_repayments)){
			_.each(debt_repayments, function(d, key){
				vm.debt_repayment_total += Math.abs(d.instalment_amount);
	    });		
    }
		
		_.each(transactions, function(t, key){
	    	var state = false;
	    	if(t.score > 0){
	    		state = true;
	    	}
	    	
	    	vm.data.Transactions[t._id] = state;
	    	vm.total = calcTotal();
	    });

	    vm.checkTransaction = function(t){	    	
	    	vm.total = calcTotal();
	    }
	    
	    function calcTotal(){
	    	var total = 0;
	    	_.each(vm.data.Transactions, function(state, tid){
	    		
	    		if(state === true){	    			
	    			total += Math.abs(getTransactionAmount(tid));
	    		}
		    });
	    	
	    	return total;
	    }
	    
	    function getTransactionAmount(tid){
	    	var amount = 0;
	    	_.each(transactions, function(t, key){
	    		if(tid == t._id){
	    			amount = t.amount;
	    		}
	    	});
	    	
	    	return amount;
	    }
			
		vm.submit = function(){
			if(vm.total == 0){
				return;
			}
	    	TransActTransactionFactory.postExpenses(vm.data)
	    		.then(function(expenses){
						$state.go('private.app.quote');
	    		}, function(error){
	    			//$state.go('private.app.dashboard.modal.affordability.progress');
	    		});
		}
		//vm.addManualExpense();
				StepFactory.setPercent(90);
	}
})();