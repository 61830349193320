(function() {
	"use strict";

	angular.module('finlayer').factory('CreditCrunchAuth', function(API, $q) {
		var factory = {};
		var prefix = 'credit_crunch/auth';
		
		factory.getQuestions = function(){			
			return API.all(prefix + '/questions').getList();
		}
		
		factory.postAnswers = function(data){
			return API.all(prefix + '/answers').post({'Answers': data});
		}
		
		factory.getAuthState = function(){
			var deferred = $q.defer();
			var p = API.one(prefix + '/state').get();
			
			p.then(function(response){
				if(response.Authenticated){
					deferred.resolve(response);
				}else{
					deferred.reject(response);
				}
			}, function(err){
				deferred.reject(err);
			});
			return deferred.promise;
		}
		
		factory.manualAuth = function(){			
			var request = API.one(prefix + '/manual').get()
				.then(function(response){
					return response.Authenticated;
				});
				return request;
		}
		
		return factory;
	});
})();
