// =========================================================================
    // FTU Steps
    // =========================================================================

    //steps
    
(function(){
    "use strict";

		angular.module('app.directives').directive('ftuSteps', function () {
    return {
        templateUrl: '/app/views/partials/ftu-steps.html',
        scope: false,
        link: function ($scope, $element, $attrs) {
            //console.log($scope.currentStep)
            $scope.$watch('currentStep', function(newStep, prevStep){
                if(typeof(newStep) == 'undefined'){
                    return;
                }

                //Color the steps
                $element.find('div[step]').each(function (i, elem){
                    var elem = $(elem);

                    if( elem.is('[step]') ){
                        step = elem.attr('step');

                        if(!isNaN(step)){
                            if(step < newStep){
                                elem.addClass('active').removeClass('current');
                            }else {
                                if (step == newStep) {
                                    elem.addClass('active').addClass('current');
                                } else {
                                    elem.removeClass('active').removeClass('current');
                                }
                            }
                        }
                    }
                }); 

                //Increase progress
                var progressElem = $element.find('.progress-wrapper .progress');
console.log('ge');
                progress = (newStep - 1) * 33;
                $(progressElem).animate( { width: progress + '%' } );
                $element.find('.progress-wrapper .zmdi-main').animate( { "left": '100%' } );
                console.log($element.find('.progress-wrapper .zmdi-main'));
            });
        }
    }
});

})();


