(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActMfaController', TransActMfaController);
	
	function TransActMfaController(API, $state, TransActAccountFactory, $stateParams,$timeout, mfa){
		var vm = this;
		vm.data = {};
		vm.data.site_id = $stateParams.site_id;
		
		vm.mfa = mfa;
		vm.submit = submit;
		
		function submit(){
			var data = vm.data;
			TransActAccountFactory.postMfaChallenge(data)
				.then(function(Status){
					if(Status == true){
						$state.go('private.app.trans_act.progress', {site_id: $stateParams.site_id});
					}
				});
		}
		
	function getSiteRefreshState(site_id){
	var timeout;

	TransActAccountFactory.getProgress(site_id)
		.then(
		function(data){
			vm.refreshState = data;
			
			if(data.refresh_code != 'auth_required' || data.error_code != 0){
			  $timeout.cancel(timeout);
				$state.go('private.app.trans_act.progress', {site_id: $stateParams.site_id});
					return;
				}else{
					if(mfa.qr_code){
						timeout = $timeout(function(){ getSiteRefreshState(site_id)}, 3000);						
					}  
				}
		}, function(response){
			$state.go('private.app.trans_act.login');
		}
	)
  }	
  
  getSiteRefreshState(vm.data.site_id);	
}
	
})();
