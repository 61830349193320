(function() {
	"use strict";

	angular.module('finlayer').factory('ShiftCtrlAssetFactory', function(API) {
		var factory = {};
		var prefix = 'shift_ctrl/asset';
		
		factory.getAll = function(){			
			return API.all(prefix).getList();
		}

		return factory;
	});
})();
