(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActStatementPasswordController', TransActStatementPasswordController);
	
	function TransActStatementPasswordController(_, $state, $stateParams, $uibModalInstance){
		var vm = this;
		vm.data = {};	
		vm.submit = submit;			
		
		function submit(){
			$uibModalInstance.close(vm.data.password);
		}
	}
})();