(function() {
	"use strict";

	angular.module('finlayer').factory('ShiftCtrlUserFactory', function(API) {
		var factory = {};
		var prefix = 'user';
		
		factory.login = function(data){			
			return API.all(prefix + '/login').post(data);
		}
		
		factory.register = function(data){			
			return API.all(prefix + '/register').post(data);
		}
		
		factory.getProfile = function(data){			
			return API.one(prefix + '/profile').get(data);
		}
		return factory;
	});
})();
