//==============================================
// Step Factory
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('StepFactory', function($timeout) {
		var factory = {};
		
		factory.setStep = function(step){
			$timeout(function(){
				setStep(step);
			}, 500);
		}
		
		factory.setPercent = function(percent){
			$timeout(function(){
				setPercent(percent);
			}, 500);
		}
		
		function setStep(newStep){					
			angular.element('.dot', '#ftu-steps').each(function (i, elem){					 
          var elem = angular.element(elem);

          if( elem.is('[step]') ){
						var step = parseInt(i) + 1;

            if(step < newStep){
	            console.log('addclass');
                elem.addClass('active').removeClass('current');
            }else {
                if (step == newStep) {
                    elem.addClass('active').addClass('current');
                } else {
                    elem.removeClass('active').removeClass('current');
                }
            }
          }                    
      });
      
       //Increase progress
          var progressElem = angular.element('.progress-wrapper .progress', '#ftu-steps');
					var progress = 0;
					
          if(newStep == 2){
	          progress = 50;
          }
          
          if(newStep == 3){
	          progress = 100;
          }
    }
		
		function setPercent(progress){
			$('.progress-wrapper .progress', '#ftu-steps').animate( { width: progress + '%' } , 1500);
      $('.progress-wrapper .zmdi-main').animate( { left: progress + '%' }, 1500 );
		}

		return factory;
	});
})();