(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActProgressController', TransActProgressController);
	
	function TransActProgressController(_, API, $state, $log, $stateParams, $timeout, TransActAccountFactory, StepFactory, EventFactory, user){
		var vm = this;
		vm.user = user;
		vm.refreshState = {progress: 0};

		var site_id = null;
				
		vm.user = user;
		
		vm.submit = function(){
			angular.element('.fields').slideUp(function(){
				angular.element('.contents').css({height: 'auto'});
			});						
		}

		if($stateParams.site_id != null){
			site_id = $stateParams.site_id;
			getSiteRefreshState(site_id);
		}else{
			getAllSitesRefreshState();
		}
		
		function getAllSitesRefreshState(){
	    var timeout;
	    
	    TransActAccountFactory.getProgressAll()
	        .then(
	        function(data){
	            vm.refreshState = data.Summary;

							_.each(data.Sites, function(site, i){

								if(site.state.error_code != 0 || site.state.state == 'failed') {
									$timeout.cancel(timeout);
									$state.go('private.app.trans_act.login', { site_id: site.site_id, error_code: site.state.error_code, error_msg: site.state.error_msg});
									return;
								}
		
								if(site.state.refresh_code == 'auth_required'){
									$state.go('private.app.trans_act.mfa', { site_id: site.site_id}) 
									return;
								}
							});
	
	            if(data.Summary.progress >= 100 && data.Summary.error_code == 0){
	                $timeout.cancel(timeout);
	            }else{                    
	                timeout = $timeout(function(){ getAllSitesRefreshState()}, 3000);
	            }
	        }, function(error){
	          $state.go('private.app.trans_act.login');
	        }
	    )
		}
		
    function getSiteRefreshState(site_id){
        var timeout;

        TransActAccountFactory.getProgress(site_id)
            .then(
            function(data){
                vm.refreshState = data;

                if(data.error_code != 0 || data.state == 'failed') {
										EventFactory.triggerRefreshFailed({"error_code": data.error_code, "error_msg": data.error_msg});		            	                
                    $timeout.cancel(timeout);
                    $state.go('private.app.trans_act.login', { site_id: site_id, error_code: data.error_code, error_msg: data.error_msg});
                    return;
                }
                
                if(data.refresh_code == 'auth_required'){
	                $timeout.cancel(timeout);
                	$state.go('private.app.trans_act.mfa', { site_id: site_id}) 
			    				return;
			    			}

                if(data.progress >= 100 && data.error_code == 0){
										EventFactory.triggerRefreshCompleted();		  
                    $timeout.cancel(timeout);										          	                
										$state.go('private.app.trans_act.accounts');
                }else{                    
										//StepFactory.setPercent(60 + (data.progress * 0.3)); 
                    timeout = $timeout(function(){ getSiteRefreshState(site_id)}, 3000);
                }
            }, function(response){
							$state.go('private.app.trans_act.login');
            }
        )
    }
    
    function init(){
	    var fieldsHeight = angular.element('.fields').outerHeight();
	    angular.element('.contents').outerHeight(fieldsHeight);
    }
    
    init();
		StepFactory.setPercent(50);
	}
})();