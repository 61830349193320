(function() {
	"use strict";

	angular.module('app.services').factory('API', function(Restangular, ToastService, $localStorage, __env) {

		//content negotiation
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/x.laravel.v1+json'
		};

		return Restangular.withConfig(function(RestangularConfigurer) {
			RestangularConfigurer
				.setBaseUrl(__env.apiUrl)
				.setDefaultHeaders(headers)
				.setErrorInterceptor(function(response) {
					if (response.status === 412) {
						ToastService.error(response.data.error);
					}
				})
				.addFullRequestInterceptor(function(element, operation, what, url, headers) {
					if ($localStorage.ConnectRequest) {
						headers.ConnectRequestId = $localStorage.ConnectRequest._id;
					}
				})
				.addResponseInterceptor(function(data) {
					return data;
				});
		});
	});

})();
