(function() {
	"use strict";

	angular.module('app.controllers').controller('WelcomeController', WelcomeController);

	function WelcomeController($log, StepFactory, $localStorage, user) {	
		var vm = this;
		vm.user = user;
		
		if($localStorage.hasOwnProperty('lead_params')){
			vm.lead_params = $localStorage.lead_params;					
		}		
		
		vm.resetLoanAmount = function(){
			delete $localStorage.lead_params;
		}
	}

})();
