//==============================================
// Event Factory
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('EventFactory', function(API) {
		var factory = {};
		var prefix = 'clients/business_fuel/events';
		
		factory.trigger = function(event){
			return API.one(prefix+'/trigger/'+event).get();
		}
		
		factory.triggerRefreshStarted = function(data){
			return API.all(prefix+'/refresh/1').post({data: data, error: {}});
		}		
		
		factory.triggerRefreshCompleted = function(){
			return API.all(prefix+'/refresh/2').post({data: {}, error: {}});

		}	
		
		factory.triggerRefreshFailed = function(error){
			return API.all(prefix+'/refresh/3').post({data: {}, error: error});
		}					
		
		return factory;
	});
})();
