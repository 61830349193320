(function() {
	"use strict";

	angular.module('app.controllers').controller('QuoteController', QuoteController);

	function QuoteController(StepFactory, MoneySummaryHtmlFactory, $window, cfpLoadingBar, user) {	
		var vm = this;	
		vm.user = user;	
		StepFactory.setPercent(100);
		
		vm.submit = function(){
			MoneySummaryHtmlFactory.getLink()
				.then(function(response){
					cfpLoadingBar.start();
					$window.location = response.link;
				});
		}
	}

})();
