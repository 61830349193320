(function() {
	"use strict";

	angular.module('app.controllers').controller('RegisterController', RegisterController);

	function RegisterController($log, $auth, $state, ToastService) {	
		var vm = this;
		
		vm.register = register;
		
		function register(){
			var user = {
					email: vm.email,
					password: vm.password,
					first_name: vm.first_name,
					surname: vm.surname					
			};
			
			$auth.signup(user)
				.then(function(response){
					$log.info(response);
				$auth.login(user)
					.then(function(response){
						$log.info(response);
						$state.go('private.app.dashboard');
					}, registerFail);
				}, registerFail);
		}
		
		function registerFail(response){
			$log.error(response);
			ToastService.error('Error occured while registering'); 
		}
		
	}

})();
