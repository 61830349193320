(function() {
	"use strict";

	angular.module('app.controllers').controller('IndexController', IndexController);

	function IndexController($window, $state, $stateParams, $log, $location, $localStorage, cfpLoadingBar, ConnectRequestFactory, ShiftCtrlUserFactory) {	
		var vm = this;							
		
		vm.user = {
			first_name: $stateParams.first_name,
			surname: $stateParams.surname,
			email: $stateParams.email,		
		};
		
		if(typeof(vm.user.email) === 'undefined'){
			if($localStorage.hasOwnProperty('lead_params')){
	//		cfpLoadingBar.start();				
	//		vm.user = Object.assign({}, $localStorage.lead_params);;
			}
		}					
		
		vm.redirected = function(){
			if($stateParams.first_name != null){
				return true;
			}else{
				return false;
			}			
		}			
		
		var login = function(user, url){
			url = url || '/welcome';
			ShiftCtrlUserFactory.login(user)
				.then(function(data){
					cfpLoadingBar.complete();
					
					$location.path(url);
				});
		}
		
		var autoLogin = function(user, url){					
			ShiftCtrlUserFactory.register(user)
			.then(function(data){
				$log.info('Registered User');
				
				login(user, url);
			}, function(){
				login(user, url);				
			});
		}				
		
		var init = function(user, url){			
				user.email = user.email.toLowerCase();
			
				ConnectRequestFactory.reset();				
				
				var params = {
					redirect_uri: $window.__env.redirect_uri,
					scope: $window.__env.scope
				};				
				 
				ConnectRequestFactory.initialize(params)
				.then(function(data){
					user.password = user.email;
					user.terms = 1;
					autoLogin(user, url);
				}, function(err){
					console.error(err);
				});
		}						

		function registerFail(response){
			$log.error(response);
			ToastService.error('Error occured while registering'); 
		}	
		
		vm.submit = function(url){
			url = url || '/welcome';			
			init(vm.user, url);
		}		
						
		if(typeof vm.user.email != 'undefined' && typeof vm.user.first_name != 'undefined' && typeof vm.user.surname != 'undefined'){
			if($stateParams.url != 'undefined'){
				vm.submit($stateParams.url);				
			}else{
				vm.submit();				
			}
		}
	}

})();
