(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActStatementUploadController', TransActStatementUploadController);
	
	function TransActStatementUploadController(_, $state, $stateParams, $localStorage, $uibModal, $log, BankStatementStatementFactory, ToastService, StepFactory){
		var site_id = null;
		var vm = this;	
		vm.data = {};
		//var previewTemplate = '<div class="dz-preview dz-file-preview dz-processing dz-success dz-complete">      <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress="" style="width: 100%;"></span></div>  <div class="dz-error-message"><span data-dz-errormessage=""></span></div><a class="dz-remove btn btn-primary btn-danger" style="font-size: 12px;" href="javascript:undefined;" data-dz-remove="">Replace File</a></div>';			
		
		if($stateParams.hasOwnProperty('bank')){
			vm.bankClass = $stateParams.bank;
		}

		vm.dropzone1 = {
			'prev': {
				title: '',
				icon: 'icon-upload-statement',
				config: {
					maxFilesize: 20,
					acceptedMimeTypes: 'application/pdf',					
					url: "/api/api/bank_statement/statement/upload/",
					headers: {"ConnectRequestId" : $localStorage.ConnectRequest._id},
					addRemoveLinks: true,		
					//previewTemplate: previewTemplate,					
					init: function() { 
						this.on("maxfilesexceeded", function(file) { this.removeAllFiles(); this.addFile(file); });
						//this.on("error", function(file){ this.removeAllFiles()});	
						this.on('removedfile', vm.dzRemoveFile);					 
						this.on('success', function(file, response, e){						
							var month = moment().subtract(1, 'month').format('MM');
							var year = moment().subtract(1, 'month').format('YYYY')
							
							var input = {
							//	'month': month,
							//	'year': year
							};
							vm.dzUploadSuccess(input, file, response, e);
							
						});
						
						this.on('error', vm.dzError);
					},		
					dictDefaultMessage : '',			
					dictInvalidFileType: 'Only bank statements in PDF format are allowed',
					success: function(){},
				//	addedfile: function(file){
						//$(file.previewElement).parent().find('.icon').hide();						
					//}																
				},
				element: null,
				uploaded: false,
				asset_id: null
			}	
		};	
		
		/*vm.dropzone2 = {
			'prev': {
				title: moment().subtract(2, 'month').format('MMMM YYYY'),
				icon: 'icon-upload-statement',
				config: {
					maxFiles: 1,
					maxFilesize: 5,
					acceptedMimeTypes: 'application/pdf',					
					url: "/api/api/bank_statement/statement/upload/",
					headers: {"ConnectRequestId" : $localStorage.ConnectRequest._id},
					addRemoveLinks: false,		
					previewTemplate: previewTemplate,					
					init: function() { 
						this.on("maxfilesexceeded", function(file) { this.removeAllFiles(); this.addFile(file); });
						this.on("error", function(file){ this.removeAllFiles()});	
						this.on('removedfile', vm.dzRemoveFile);					 
						this.on('success', function(file, response, e){
							var month = moment().subtract(2, 'month').format('MM');
							var year = moment().subtract(2, 'month').format('YYYY')
							
							var input = {
								'month': month,
								'year': year
							};
							vm.dzUploadSuccess(input, file, response, e);
							vm.dropzone2.prev.uploaded = true;							
						});
						
						this.on('error', vm.dzError);
					},		
					dictDefaultMessage : 'Drop files here or click this box to upload.',			
					dictInvalidFileType: 'Only bank statements in PDF format are allowed'																
				},
				element: null,
				uploaded: false,
				asset_id: null
			}	
		};
		
		vm.dropzone3 = {
			'prev': {
				title: moment().subtract(3, 'month').format('MMMM YYYY'),
				icon: 'icon-upload-statement',
				config: {
					maxFiles: 1,
					maxFilesize: 5,
					acceptedMimeTypes: 'application/pdf',					
					url: "/api/api/bank_statement/statement/upload/",
					headers: {"ConnectRequestId" : $localStorage.ConnectRequest._id},
					addRemoveLinks: false,		
					previewTemplate: previewTemplate,					
					init: function() { 
						this.on("maxfilesexceeded", function(file) { this.removeAllFiles(); this.addFile(file); });
						this.on("error", function(file){ this.removeAllFiles()});	
						this.on('removedfile', vm.dzRemoveFile);					 
						this.on('success', function(file, response, e){
							var month = moment().subtract(3, 'month').format('MM');
							var year = moment().subtract(3, 'month').format('YYYY')
							
							var input = {
								'month': month,
								'year': year
							};
							vm.dzUploadSuccess(input, file, response, e);
							vm.dropzone3.prev.uploaded = true;							
						});
						
						this.on('error', vm.dzError);
					},		
					dictDefaultMessage : 'Drop files here or click this box to upload.',			
					dictInvalidFileType: 'Only bank statements in PDF format are allowed'																
				},
				element: null,
				uploaded: false,
				asset_id: null
			}	
		};	*/					
		
		vm.dzUploadSuccess = function(input, file, response, e){
			input.asset_id = response._id;
			input.bank_id = $stateParams.bank_id;						
			
			BankStatementStatementFactory.postParse(input)
				.then(function(){						
						file.previewElement.classList.add("dz-success");
						vm.dropzone1.prev.uploaded = true;									
//					$state.go('private.app.trans_act.accounts');
				}, function(err){
					console.log(err);
						if(err.status == 403){
										
							var modalInstance = $uibModal.open({
					      animation: true,
					      templateUrl: './views/modules/trans_act/statement_password/statement_password.html',
								bindToController: true,
								controllerAs: 'vm',					      
					      controller: 'TransActStatementPasswordController',
					    });
					
					    modalInstance.result.then(function (password) {
								input.password = password;
								BankStatementStatementFactory.postParse(input)
									.then(function(){
										file.previewElement.classList.add("dz-success");		
										vm.dropzone1.prev.uploaded = true;																	
									}, function(){
										vm.dzError(file);
									});
					    }, function () {
					      $log.info('Modal dismissed at: ' + new Date());
					    });
							
						}else{
							vm.dzError(file);											
						}
				});
		}
		
		vm.dzError = function(file){
			file.previewElement.classList.add("dz-error");			
			
			console.log($('.dz-error-message span', file.previewElement));
			$('.dz-error-message span', file.previewElement).html('We could not process the bank statements that you have uploaded. It may be due to the format that they are in. Please contact our consultants at 021 914 0384 and they will assist you with submitting your statements to us. <br /> <br /><strong>Please remove the file before submitting your statements.</strong>')	
		}
		
		vm.dzRemoveFile = function(file){
			//$(file.previewElement).parent().find('.icon').removeClass('icon-upload-statement').removeClass('icon-circle-cross').addClass('icon-circle-tick');						
		}
		
		vm.canSubmit = function(){
			if(vm.dropzone1.prev.uploaded){
				return true;
			}else{
				return false;
			}			
		}
		
		vm.submit = function(){
			if(vm.canSubmit()){				
				$state.go('private.app.trans_act.accounts_enable');
			}
		}
		StepFactory.setPercent(60);		
	}
})();