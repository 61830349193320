// =========================================================================
    // INPUT FEILDS MODIFICATION
    // =========================================================================

    //Add blue animated border and remove with condition when focus and blur
    
(function(){
    "use strict";

		angular.module('app.directives').directive('fgLine', function(){
        return {
            restrict: 'C',
						link: function(scope, element) { 
							var input = angular.element('.form-control', element);
							var modalName = input.attr('ng-model');

							scope.$watch(modalName, function (newVal) {
								if(angular.isDefined(newVal)){
									if(newVal.length != 0){
										angular.element(element).addClass('fg-toggled')
									}	
								}							
							}); 

              angular.element(element).on('focus', '.form-control', function(){
                  angular.element(this).closest('.fg-line').addClass('fg-toggled');
              })
              
              angular.element(element).on('change', '.form-control', function(){
                  angular.element(this).closest('.fg-line').addClass('fg-toggled');
              })

              angular.element(element).on('blur', '.form-control', function(){
                  var p = angular.element(this).closest('.form-group');
                  var i = p.find('.form-control').val();
                  if (p.hasClass('fg-float')) {
                      if (i.length == 0) {
                          angular.element(this).closest('.fg-line').removeClass('fg-toggled');
                      }
                  }
                  else {
                      angular.element(this).closest('.fg-line').removeClass('fg-toggled');
                  }
              });

            }
        }
        
    })

})();