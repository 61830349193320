(function(){
		"use strict";

	angular.module('app.filters').filter('server_date', function(){
		return function(input) {
			try {
				var t = input.split(/[- :]/);
				return new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);  
			} catch(e){
				return input;
			}
		};
	});
})();