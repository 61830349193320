//==============================================
// Profile Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('ProfileFactory', function(API, $localStorage, $q) {
		var factory = {};
		
		function splitCamelCase(s) {
	    return s.split(/(?=[A-Z])/).join(' ');
		}		
		
		factory.postProfile = function(data){
			return API.all('profile').post({Profile: data});
		}
		
		factory.getProfile = function(){
			return API.one('profile').get();
		}			
		
		return factory;
	});	
})();