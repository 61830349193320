(function() {
	"use strict";

	angular.module('app.controllers').controller('CreditCrunchConfirmIdentityController', CreditCrunchConfirmIdentityController);
	
	function CreditCrunchConfirmIdentityController(API, $state, $timeout, $log, CreditCrunchIdentity, CreditCrunchAuth, user){
		var vm = this;
		vm.confirm = confirm;
		vm.user = user;
		
		function confirm(){			
			var data = {
				identifier : vm.identifier,
				country: 'ZA'
			}
			
			CreditCrunchIdentity.create(data)
				.then(function(Status){	
					
						if(Status == true){
							CreditCrunchAuth.manualAuth()
								.then(function(Authenticated){
									if(Authenticated == true){
										$state.go('private.app.trans_act.disclaimer', {}, {reload: true});
									}else{
										$state.go('private.app.credit_crunch.declined');													
									}
								}, function(err){
									$state.go('private.app.credit_crunch.declined');			
								});
						}else{
							$state.go('private.app.credit_crunch.declined');			
						}

				});
		}
	}
	
})();
