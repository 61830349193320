(function() {
	"use strict";

	angular.module('app.controllers').controller('TransActConfirmOverdraftController', TransActConfirmOverdraftController);
	
	function TransActConfirmOverdraftController(_, API, $state, $log, TransActAccountFactory, StepFactory, ProfileFactory, accounts){
		var vm = this;
		vm.accounts = accounts;
		
		vm.bankAccounts = _.groupBy(accounts, function(b) {					
		    return b.site.title;
		});
	
		vm.submit = function(){
			console.log(vm.data);
			ProfileFactory.postProfile(vm.data)
			.then(function(){
				$state.go('private.app.complete');
			});			
		}
				StepFactory.setPercent(90);
	}
})();