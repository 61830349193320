(function(){
	"use strict";

	angular.module('app.routes').config(function($stateProvider, $urlRouterProvider){

		var getView = function(viewName, parent){
			var src;
			if(parent != null){
				src = './views/' + parent + '/' + viewName + '/' + viewName + '.html';
			}else{
				src = './views/' + viewName + '/' + viewName + '.html'; 
			}
			return src;
		};
		
		var getLayout = function(viewName){
			return './views/layouts/' + viewName + '.html';
		};

		$urlRouterProvider.otherwise('/');

		$stateProvider
		.state('public', {				
				abstract: true,				
				templateUrl: getLayout('public')
		})
	.state('public_full', {
			abstract: true,
			templateUrl: getLayout('public_full'),
		})
		.state('public_full.app', {
			abstract: true,
			data: {}, 
			views:{
				header: {
					templateUrl: getView('header', null)
				},
				steps:{
					templateUrl: getView('steps', null)
				},
				main: {},
				footer: {
					templateUrl: getView('footer', null)
				}
			}								
		})
		.state('index', {			
			url: '/?email&first_name&surname&url', 
			data: {}, 
			templateUrl: getView('index', null),
			controller: 'IndexController',
			controllerAs: 'vm'	
		})					
		.state('public.confirm', {
			url: '/confirm?email&first_name&surname&amount&location&jot_form_id',
			data: {}, 
			views:{
				main: {
					templateUrl: getView('confirm', null),
					controller: 'ConfirmController',
					controllerAs: 'vm'
				}	
			}							
		})
		.state('public_full.app.terms', {   
			url:'/terms',
			views:{
				'main@public_full': {
					templateUrl: getView('terms', null),		
					controller: 'TermsController',
					controllerAs: 'vm'
				}
			}			
		})		
		.state('public.register', {
			url: '/register',
			data: {}, 
			views:{
				main: {
					templateUrl: getView('register', null),
					controller: 'RegisterController',
					controllerAs: 'vm'
				}	
			}								
		})
		.state('private', {
			abstract: true,
			templateUrl: getLayout('app'),
			resolve: {
				user: function(ShiftCtrlUserFactory, $log, $q){
					$log.info('Getting user');
					var deferred = $q.defer();
					
					ShiftCtrlUserFactory.getProfile()
						.then(function(user){
							deferred.resolve(user.User);
						}, function(err){
							$log.error(err);
							deferred.reject(err);
						});
						return deferred.promise;
				}
			}
		})
		.state('private.app', {
			abstract: true,
			data: {}, 
			views:{
				header: {
					templateUrl: getView('header', null)
				},
				steps:{
					templateUrl: getView('steps', null)
				},
				main: {},
				footer: {
					templateUrl: getView('footer', null)
				}
			}								
		})
		
		.state('private.app.welcome', {
			url: '/welcome',
			views:{
				'main@private': {
					templateUrl: getView('welcome', null),
					controller: 'WelcomeController',
					controllerAs: 'vm'
				}	
			},
			resolve:{
				payer: function($q, $sessionStorage){
					var deferred = $q.defer();					
					deferred.resolve($sessionStorage.payer);
					return deferred.promise;
				}
			}						
		})
		
		.state('private.app.credit_crunch', {
			url: '/credit_crunch',
			abstract: true				
		})		
		.state('private.app.credit_crunch.confirm_identity', {
			url: '/confirm_identity',
			views:{
				'main@private': {
					templateUrl: getView('confirm_identity', 'modules/credit_crunch'),
					controller: 'CreditCrunchConfirmIdentityController',
					controllerAs: 'vm'
				}	
			}						
		})		
		.state('private.app.credit_crunch.declined', {
			url: '/declined',
			views:{
				'main@private': {
					templateUrl: getView('declined', 'modules/credit_crunch'),
					controller: 'CreditCrunchDeclinedController',
					controllerAs: 'vm'
				}	
			}						
		})
		.state('private.app.credit_crunch.credit_summary', {
			url: '/summary',
			views:{
				'main@private': {
					templateUrl: getView('credit_summary', 'modules/credit_crunch'),
					controller: 'CreditCrunchCreditSummaryController',
					controllerAs: 'vm'
				}	
			},
			resolve:{
				snapshot:['CreditCrunchSnapshotFactory', function(CreditCrunchSnapshotFactory){
					return CreditCrunchSnapshotFactory.get();
				}]				
			}						
		})
		
		.state('private.app.document_upload', {  
			url:'/documents', 
			views:{
				'main@private': {
					templateUrl: getView('document_upload', null),		
					controller: 'DocumentUploadController',
					controllerAs: 'vm'				
				}
			},
			resolve:{
				assets: ['ShiftCtrlAssetFactory', function(ShiftCtrlAssetFactory){
					return ShiftCtrlAssetFactory.getAll();
				}]
			}  
		})			
		
		.state('private.app.trans_act', {
			url: '/trans_act',
			abstract: true				
		})
		.state('private.app.trans_act.disclaimer', {
			url: '/disclaimer',
			views:{
				'main@private': {
					templateUrl: getView('disclaimer', 'modules/trans_act'),
					controller: 'TransActDisclaimerController',
					controllerAs: 'vm'
				}	
			}						
		})
		.state('private.app.trans_act.options', {
			url: '/options',
			views:{
				'main@private': {
					templateUrl: getView('options', 'modules/trans_act'),
					controller: 'TransActOptionsController',
					controllerAs: 'vm'
				}	
			}						
		})		
		.state('private.app.trans_act.statement_type', {
			url: '/statement_type',
			views:{
				'main@private': {
					templateUrl: getView('statement_type', 'modules/trans_act'),
					controller: 'TransActStatementTypeController',
					controllerAs: 'vm'
				}	
			},
			resolve:{
				banks: ['BankStatementStatementFactory', function(BankStatementStatementFactory){
					return BankStatementStatementFactory.getBanks();
				}]
			}  							
		})		
		.state('private.app.trans_act.statement_upload', {  
			url:'/statement_upload?bank_id?bank', 
			views:{
				'main@private': {
					templateUrl: getView('statement_upload', 'modules/trans_act'),		
					controller: 'TransActStatementUploadController',
					controllerAs: 'vm'				
				}
			}, 
		})	
		
		/*.state('private.app.trans_act.statement_password', {  
			url:'/statement_password?bank_id&statement_date&asset_id', 
			views:{
				'main@private': {
					templateUrl: getView('statement_password', 'modules/trans_act'),		
					controller: 'TransActStatementPasswordController',
					controllerAs: 'vm'				
				}
			}, 
		})		*/
		.state('private.app.trans_act.login', {
			url:'/login?site_id&error_code&error_msg', 
			views:{
				'main@private': {
					templateUrl: getView('login', 'modules/trans_act'),
					controller: 'TransActLoginController',
					controllerAs: 'vm'
				}	
			},
			resolve:{
				banks: ['TransActBankFactory', function(TransActBankFactory){
					return TransActBankFactory.getPopular();
				}],
				site: ['$stateParams', 'TransActSiteFactory', function($stateParams, TransActSiteFactory){
					if($stateParams.site_id != null){
						return TransActSiteFactory.get($stateParams.site_id);
					}
					return null;
				}]
			}  							
		})
		.state('private.app.trans_act.progress', {   
			url:'/progress?site_id',
			views:{
				'main@private': {
					templateUrl: getView('progress', 'modules/trans_act'),		
					controller: 'TransActProgressController',
					controllerAs: 'vm'/*,
					params:{
						site_id: null
					}				*/
				}
			},
			resolve: {
				provinces: ['ProvinceFactory', function(ProvinceFactory){
					return ProvinceFactory.getAll();
				}],
				payer: function($q, $sessionStorage){
					var deferred = $q.defer();					
					deferred.resolve($sessionStorage.payer);
					return deferred.promise;
				}
			}   
		})
		.state('private.app.trans_act.mfa', {   
			url:'/mfa?site_id',
			views:{
				'main@private': {
					templateUrl: getView('mfa', 'modules/trans_act'),		
					controller: 'TransActMfaController',
					controllerAs: 'vm'/*,
					params:{
						site_id: null
					}				*/
				}
			},
			resolve: {
				mfa: ['$q', '$stateParams', 'TransActAccountFactory', function($q, $stateParams, TransActAccountFactory){
					var deferred = $q.defer;
					
					if($stateParams.hasOwnProperty('site_id')){
						return TransActAccountFactory.getMfaChallenge($stateParams.site_id);	
					}else{
						$state.go('private.app.trans_act.login');
						deferred.reject();				
					}
					
					return deferred.promise;					
				}]
			}   
		})
		
		.state('private.app.trans_act.accounts', {   
			url:'/accounts',
			views:{
				'main@private': {
					templateUrl: getView('accounts', 'modules/trans_act'),		
					controller: 'TransActAccountsController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				accounts: ['_', 'TransActAccountFactory', '$q', '$state', function(_, TransActAccountFactory, $q, $state){
					var deferred = $q.defer();
					
					TransActAccountFactory.getAll()
						.then(function(Accounts){
							if(Accounts.length == 0){
								console.log('0 accounts');
								$state.go('private.app.trans_act.options');
								deferred.reject();
							}else{
								console.log('> 1 accounts');								
								deferred.resolve(Accounts);
							}
						});
						
						return deferred.promise;
				}]
			}   
		})
		.state('private.app.trans_act.accounts_enable', {   
			views:{
				'main@private': {
					templateUrl: getView('accounts', 'modules/trans_act'),		
					controller: 'TransActAccountsController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				accounts: ['_', 'TransActAccountFactory', '$q', '$state', function(_, TransActAccountFactory, $q, $state){
					var deferred = $q.defer();
					
					TransActAccountFactory.getAll()
						.then(function(Accounts){
								var data = {};
								
								_.each(Accounts, function(acc, i){	
									var account = {};		
									if(acc.source == 'BankStatement'){
										account[acc._id] = true;
										_.extend(data, account);										
									}													
								});									
								
								
								if(!_.isEmpty(data)){
									TransActAccountFactory.postEnable(data)
					    		.then(function(accounts){
										$state.go('private.app.trans_act.confirm_overdraft');
					    		}, function(error){
					    			$state.go('private.app.trans_act.options');
					    		});														
								}else{
									$state.go('private.app.trans_act.accounts')
								}									
							deferred.reject();	
						});
						
						return deferred.promise;
				}]
			}   
		})			
		.state('private.app.trans_act.confirm_overdraft', {   
			url:'/confirm_overdraft',
			views:{
				'main@private': {
					templateUrl: getView('confirm_overdraft', 'modules/trans_act'),		
					controller: 'TransActConfirmOverdraftController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				accounts: ['_', 'TransActAccountFactory', '$q', '$state', function(_, TransActAccountFactory, $q, $state){
					var deferred = $q.defer();
					
					TransActAccountFactory.getAll()
						.then(function(Accounts){
							if(Accounts.length == 0){
								$state.go('private.app.trans_act.options');
								deferred.reject();
							}else{
								deferred.resolve(Accounts);
							}
						});
						
						return deferred.promise;
				}]
			} 
		})		
		.state('private.app.trans_act.debt_repayments', {   
			url:'/debt_repayments',
			views:{
				'main@private': {
					templateUrl: getView('debt_repayments', 'modules/trans_act'),		
					controller: 'CreditCrunchDebtRepaymentsController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				debt_repayments: ['CreditCrunchReportFactory', '$q', '$state', function(CreditCrunchReportFactory, $q, $state){
					var deferred = $q.defer();
					
					CreditCrunchReportFactory.getDebtRepayments()
						.then(function(response){
							deferred.resolve(response);
						}, function(){
							$state.go('private.app.trans_act.confirm_expenses');
							deferred.reject();
						});
						
						return deferred.promise;
				}]
			}  
		})		
		.state('private.app.trans_act.confirm_expenses', {   
			url:'/confirm_expenses',
			views:{
				'main@private': {
					templateUrl: getView('confirm_expenses', 'modules/trans_act'),		
					controller: 'TransActConfirmExpensesController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				transactions: ['TransActTransactionFactory', function(TransActTransactionFactory){
					return TransActTransactionFactory.getExpenses();
				}],
				debt_repayments: ['CreditCrunchReportFactory', '$q', '$state', function(CreditCrunchReportFactory, $q, $state){
					var deferred = $q.defer();
					
					CreditCrunchReportFactory.getDebtRepayments()
						.then(function(response){
							deferred.resolve(response);
						}, function(){
							deferred.resolve({});							
						});
						
						return deferred.promise;
				}],
				categories: ['TransActCategoryFactory', function(TransActCategoryFactory){
					return TransActCategoryFactory.getAll();
				}]
			}  
		})
		.state('private.app.quote', {   
			url:'/quote',
			views:{
				'main@private': {
					templateUrl: getView('quote', null),		
					controller: 'QuoteController',
					controllerAs: 'vm'
				}
			}			
		})
		
		.state('private.app.complete', {   
			url:'/complete',
			views:{
				'main@private': {
					templateUrl: getView('complete', null),
					controller: 'CompleteController',
					controllerAs: 'vm'					
				}
			},
			resolve:{
				event_export_lead: ['EventFactory', '$q', function(EventFactory, $q){
					var deferred = $q.defer();
					
					EventFactory.trigger('ExportLead')
						.then(function(response){
							deferred.resolve(response);
						}, function(){
							deferred.resolve({});							
						});
						
						return deferred.promise;
				}],
				event_export_assets: ['EventFactory', '$q', function(EventFactory, $q){
					var deferred = $q.defer();
					
					EventFactory.trigger('ExportAssets')
						.then(function(response){
							deferred.resolve(response);
						}, function(){
							deferred.resolve({});							
						});
						
						return deferred.promise;
				}],													
			}						
		})	
								
		.state('logout', {
			url: '/logout',
			controller: function($auth, $state){
				$auth.logout();
				$state.go('public.login');
			},
			controllerAs: 'vm'	
		});			
	});
})();
