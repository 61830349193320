(function() {
	"use strict";

	angular.module('app.controllers').controller('HomeController', HomeController);

	function HomeController($log, $state, $stateParams, $localStorage, ConnectRequestFactory) {	
		var vm = this;
		init();
		
		function init(){
			$log.info($stateParams);
			if($stateParams.redirect_uri != null && $stateParams.scope != null){
				ConnectRequestFactory.reset();				
				
				ConnectRequestFactory.initialize($stateParams)
				.then(function(data){
					$state.go('public.autologin');
				}, function(err){
					
				});
			}
	
		}
	}

})();
