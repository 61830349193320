//==============================================
// TransAct Category Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('TransActCategoryFactory', function($q) {
		var factory = {};
		var categories = [
			'Accommodation',
			'Education',
			'Food',
			'Maintenance',
			'Medical',
			'Transport',
			'Water and electricity'
		];
		
		factory.getAll = function(){
			var deferred = $q.defer();			
			deferred.resolve(categories);
			return deferred.promise;
		}
		
		return factory;
	});
})();