(function (window) {
  window.__env = window.__env || {};

  // API url
  window.__env.apiUrl = '/api/api';
  window.__env.clientId = 'za.co.businessfuel';
  window.__env.redirect_uri = 'https://uat-moneysummary.limitlesstech.io/auth';
  window.__env.scope = 'transactions,accounts';
  window.__env.tracking = {
	  providers: {
	 	  ga: {
			  id: '',
			  enabled: false
		  } 
	  }
  } 

  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  window.__env.enableDebug = true;
}(this));
