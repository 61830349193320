(function() {
	"use strict";

	angular.module('finlayer').factory('TransActAffordabilityFactory', function(API) {
		var factory = {};
		var prefix = 'finlayer/trans_act/affordability';
		
		factory.get = function(){			
			return API.one(prefix).get();
		}
			
		factory.postGrossIncome = function(data){
			return API.all(prefix + '/gross-income/'+data).post();
		}
	
		return factory;
	});
})();
